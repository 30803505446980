export const graphicsLibrary = {
    Character: {
        AngularLibrary:
            "p[0,1,2,4]x0141,x26,x023,222x36,222x018,22x312,22x013,222x316,222x09,2x310,11x310,2x08,2x310,11x310,2x08,2x39,1111x39,2x08,2x39,1111x39,2x08,2x38,x16,x38,2x08,2x38,113311x38,2x08,2x38,113311x38,2x09,2x36,11133111x36,2x010,2x36,11333311x36,2x010,2x35,x110,x35,2x010,2x35,x110,x35,2x010,23333111x36,11133332x010,2333311x38,1133332x010,2333111x38,1113332x010,233311x310,113332x010,2x320,2x011,22x316,22x014,22x312,22x018,22x38,22x022,2x36,2x025,223322x028,22x079,",
        PreactLibrary:
            "p[0,1,3,4,17,20]x012,10011001x022,100112211001x018,1200123443210021x014,12001224433442210021x011,1001124434444344211001x08,1000123443x46,3443210001x05,100125444433444433444452100100050122442223443443443222442210501012442x15,22444422x15,244210120154412444321133112344421445102201244134344442112444434314421022012441243434212212434342144210220124421433421244212433412442102201243412442134334312442143421022012434213214421124412312434210220124434211443111134411243442102201244342114431111344112434421022012434213214421124412312434210220124341244213433431244214342102201244214334212442124334124421022012441243434212212434342144210220124413434444211244443431442102201544124443211331123444214451021012442x15,22444422x15,244210105012243222344344344322234221050001001254444334444334444521001x05,1000123443x46,3443210001x08,1001124434444344211001x011,12001224433442210021x014,1200123443210021x018,100112211001x022,10011001x012,",
        ReactLibrary:
            "p[0,1,2,3]x039,x26,x06,x26,x013,2x36,220022x36,2x011,2x39,22x39,2x010,2333222x38,2223332x010,23320002x36,20002332x010,2332000023333200002332x010,23320002x36,20002332x010,2332022233223322202332x010,23332x312,23332x08,222x320,222x05,2x326,20002x37,2333x26,3332x37,202333322x35,22111122x35,22333322332200233332x16,23333200223322320000233322x16,22333200002322320000233322x16,22333200002322332200233332x16,23333200223322333322x35,22111122x35,223333202x37,2333x26,3332x37,20002x326,2x05,222x320,222x08,23332x312,23332x010,2332022233223322202332x010,23320002x36,20002332x010,2332000023333200002332x010,23320002x36,20002332x010,2333222x38,2223332x010,2x39,22x39,2x011,2x36,220022x36,2x013,x26,x06,x26,x039,",
        SolidLibrary:
            "p[0,1,2,3,4,2]x043,22225x25,x021,23x19,522x017,2x114,22x015,5x116,22x012,2x119,22x09,2x122,2x08,2113333x117,2x06,2x312,x112,2x06,5x314,x19,2x06,2x317,x16,4x07,2x319,11112x07,2x320,115x08,2x310,1111x36,22x010,2x37,113333111322x013,2333111x39,152x011,222111x314,12x09,5x321,2x08,2x322,12x06,2x324,2x06,2x310,2222x39,2x06,2x38,x29,x37,2x06,2x35,x213,x35,2x06,2333x217,3335x07,23x220,332x08,x223,x011,x221,x013,x218,x016,x215,x019,x212,x024,x26,x045,",
        SvelteLibrary:
            "p[0,1,2,4]x014,x27,x022,222x37,22x019,2x312,2x016,22x314,2x014,2x37,x16,33332x011,22x36,x19,33332x09,2x37,x111,3332x08,2x36,x17,3x15,3332x07,2x36,x17,333111133332x06,23333x17,x36,11133332x05,23333x17,x38,1133332x05,23333x15,x315,2x06,2333x15,x316,2x06,23331111x35,x16,x36,2x06,233311113333x18,33332x07,2333x15,3x111,33332x06,23333x111,3x15,3332x07,23333x18,333311113332x06,2x36,x16,x35,11113332x06,2x316,x15,3332x06,2x315,x16,3332x05,2333311x38,x17,33332x05,23333111x36,x17,33332x06,233331111333x18,x35,2x07,2333x15,3x17,x36,2x08,2333x112,x36,2x09,23333x19,x36,22x011,2x35,x15,x37,2x014,2x314,22x016,2x312,2x019,22x37,222x022,x27,x018,",
        VueLibrary:
            "p[0,1,2,3,4]x097,x211,x06,x211,0002x35,x46,200002x46,x35,2002x36,x46,2002x46,x36,2002x36,x46,2002x46,x36,2002x37,x46,22x46,x37,20021x36,x46,22x46,x36,1200021x36,x412,x36,12100021x36,x412,x36,121000021x36,x410,x36,12x06,21x37,x48,x37,12x07,21x36,x48,x36,12x08,21x37,x46,x37,12x09,21x36,x46,x36,12x011,21x36,4444x36,12x012,21x36,4444x36,12x013,21x36,44x36,12x014,21x314,12x015,21x312,12x016,21x312,12x017,21x310,12x019,21x38,12x020,21x38,12x021,21x36,12x022,21x36,12x023,21333312x024,21333312x025,211112x027,2222x047,",
        Lady: {
            normal: {
                normal: "p[0,1,2,4]x05,x26,x09,2x36,2x07,2x38,2x06,2332332332x05,2332122123320000232x16,2320000221121121122000022112112112200002321133112320000223x26,32200022121222212122002112x16,211200022x18,22x05,2x18,2x07,2x16,2x09,x26,",
                up: "p[0,1,2,4]x05,x26,x09,2x36,2x07,2x38,2x06,2x38,2x05,2x310,200002x310,200002x310,200002x310,200002x310,2000022x38,2200022122333322122002112122221211200022x18,22x05,2x18,2x07,2x16,2x09,x26,",
                left: "p[0,1,2,4]x06,2222x010,22333322x07,2x38,2x05,232x38,200002212x37,2x05,21122x35,2x05,21211233332x05,21211233332x05,21111233332x06,2312233332x07,x25,3332x07,212112332x07,21211232x07,21112222x08,2x16,2x09,x26,",
            },
            walking: {
                normal: "p[0,1,2,4]x06,x26,x09,2x36,2x07,2x38,2x06,2332332332x05,2332122123320000232x16,232000232112112112200023211211211220023322113311232002321x27,332000221212221222x06,2x15,2112x06,2x15,2112x07,2x15,22x09,x25,",
                up: "p[0,1,2,4]x05,x26,x09,2x36,2x07,2x38,2x06,2x38,2x05,2x310,200002x310,200002x310,20002x311,20002x310,200002x39,22x05,222x35,22200002111x25,11200002x17,2112x05,2x17,22x07,x27,",
                left: "p[0,1,2,4]x05,2222x010,22333322x07,2x38,2x05,232x38,200002212x37,2x05,21122x36,20000212112x36,20002121112x35,20002111132x35,20000231222233332x05,x210,x07,2121122x08,211211212x07,211122112x08,x27,",
            },
        },
        Oak: {
            normal: {
                normal: "p[0,1,2,4]x05,x26,x07,222x16,2x07,2122122212x06,2211211122x05,22x18,22000212131111312120021111233211112002131121121131200022x18,2200022121133112122021121x26,121122112112222112112022211233211222000211122221112x05,x210,x05,x25,00x25,00",
                up: "p[0,1,2,4]x05,x26,x09,2x16,222x05,2x18,2x06,2x18,2x05,22x18,22000212x18,212002112x16,211200213121111213120002211322311220002222x16,2222021221322223122122122x18,2212022x110,22000211132231112x05,x210,x05,x25,00x25,00",
                left: "p[0,1,2,4]x06,x25,x08,222x15,2x06,2x19,2x06,21222211112x05,22111121112x06,2113112222x06,2321112112x06,2121113112x06,2x17,2x08,2311122x010,x25,12x09,2221122x09,23211212x08,22122112x08,x28,x07,x26,",
            },
            walking: {
                normal: "p[0,1,2,4]x05,x26,x07,222x16,2x07,2122122212x06,2211211122x05,22x18,22000212131111312120021111233211112002131121121131200022x18,2200022121133112312002121x28,120002111233221120000211x26,112x05,x27,022x08,2222",
                up: "p[0,1,2,4]0000x26,x09,2x16,222x05,2x18,2x06,2x18,2x05,22x18,22000212x18,212002112x16,211200213121111213120002211322311220002122x16,2222002221322223121120022x18,21120002x18,2220000222231322x08,x26,",
                left: "p[0,1,2,4]x05,x25,x08,222x15,2x06,2x19,2x06,21222211112x05,22111121112x06,2113112222x06,2321112112x06,2121113112x06,2x17,2x08,23111222x09,x26,12x08,2222112120000222233211212x05,x211,x06,222000222",
            },
        },
        Player: {
            normal: {
                normal: "p[0,1,2,4]x05,x26,x09,2x36,2x07,2x38,2x06,2x38,2x05,2223111132220000221x26,122000212x18,21200211112112111120002211211211220000222113311222000211x28,11200211x28,112000222332233222x05,2322332232x06,2333223332x07,222002220000",
                up: "p[0,1,2,3,4]x05,x26,x09,2x46,2x07,2x48,2x06,2x48,2x05,22x48,220000222x46,22200021x210,1200211x28,1120002211222211220000222244442222000212242222422120021224411442212000222244442222x05,24x26,32x06,2444224442x07,222002220000",
                left: "p[0,1,2,4]x05,x26,x09,2x36,2x07,2x38,2x05,221x37,2000021111x35,222000022333x27,x05,21211x26,x05,2121121122x06,2x17,2x08,231112232x08,x25,332x09,2211232x09,2211232x08,2332222x09,233332x011,2222",
            },
            walking: {
                normal: "p[0,1,2,4]x021,x26,x09,2x36,2x07,2x38,2x06,2x38,2x05,2223111132220000x212,000212x18,212002111121121111200222112112112200021221133112320000x210,12x05,22322221122x06,222332112x07,23322022x09,222",
                up: "p[0,1,2,4]x021,x26,x09,2x36,2x07,2x38,2x06,2x38,2x05,22x38,220000222x36,22200021x210,1200211x28,1120022211222211220002122233332222000022232222321120000223311332112x05,2233332222x06,232222x011,222",
                left: "p[0,1,2,4]x021,x26,x09,2x36,2x07,2x38,2x05,221x37,2000021111x35,222000022333x27,x05,21211x26,x05,2121121122x06,2x17,2x08,231112232x08,x26,32x06,x26,1122x05,233233211232x05,233x25,332x06,222000022000",
            },
        },
        Scientist: {
            normal: {
                normal: "p[0,1,2,4]x06,2222x010,x28,x07,2222112222x06,22x16,22x05,22x18,220002121221122121200211211221121120021321122112312000221221122122000022211331122200021123222232112002112112211211200022x18,22x05,2x18,2x06,x210,x07,222002220000",
                up: "p[0,1,2,4]x06,2222x010,x28,x07,x210,x06,x210,x05,x212,00021x210,120021x210,120021x210,12000221x26,1220000222x16,2220002122322223221200212x18,21200022x18,22x05,2111221112x06,x210,x07,222002220000",
                left: "p[0,1,2,4]0000x26,x09,x28,x07,x210,x06,211x27,x06,2111x27,x05,22111x26,x05,21x29,x05,2121121122x06,22x16,2x08,2311122x010,x25,12x09,2121122x09,2121122x09,2112212x09,x27,x010,2222",
            },
            walking: {
                normal: "p[0,1,2,4]x06,2222x010,x28,x07,2222112222x06,22x16,22x05,22x18,22000212122112212120021121122112112002132112211231200222122112212200021221133112220000x210,12x05,22122221122x06,221112112x07,x25,022x09,222",
                up: "p[0,1,2]x06,2222x010,x28,x07,x210,x06,x210,x05,x212,00021x210,120021x210,120021221222212212002221112211122000212221111222200002221222212112000022x16,2112x05,2211112222x06,x26,x011,222",
                left: "p[0,1,2,4]x05,x26,x09,x28,x07,x210,x06,211x27,x06,2111x27,x05,22111x26,x05,21x29,x05,2121121122x06,22x16,2x08,231112212x08,x26,12x06,2221121122x05,222111211222x05,x211,x06,222000022",
            },
        },
    },
    Solid: {
        Book: "p[0,1,2,3]00x25,000x25,00231111222111132223133313133313222311113313331322231313131333132223x15,3x15,32223x15,31313132223133313x15,32223x15,3x15,3222x313,x217,",
        Bookshelf:
            "p[0,1,2,3,4]0x214,02x114,221x312,4221x312,4221x312,4221x312,4221x312,4221x312,422x414,224x212,42242323232332324224232121233232422421212121121242242123232112124224222323x26,4224x212,4224x312,4224x212,422423323233233242242332121121124224211212112112422422223x27,4224x212,4224x312,4224x212,4224x35,22x35,4224x35,22x35,4224x35,22x35,4224x35,22x35,4x218,4x312,420x214,0",
        LabComputer:
            "p[0,1,2,3,4]001x210,11x26,1x012,2x110,22x16,2x012,2x110,22x16,2x012,2x310,22x16,2x012,23x28,322x36,2x012,23241144423223222232x47,x05,232x46,2322x46,24x15,4x05,232411114232234334322133314x05,23x28,32234334322211114x05,234x36,4322x46,24231314x06,x210,44x26,44211114x05,2x19,324002141242x45,x05,2x410,2400244422x011,24114141414240023332x012,2344141414424444222x014,x210,",
        Table3x1:
            "p[0,1,2,3,4]0x246,02x146,221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1221x344,1x250,x446,x249,02444422x432,22444420024444x236,444420042222x436,222200",
        Table2x2:
            "p[0,1,2,3,4]x0257,x230,02x130,221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4221x328,4222x428,x235,x430,20x230,0024442x420,24442002133x222,33320021332x320,2333200x25,x320,x25,0",
        WallScroll:
            "p[0,1,2,3,4]0x214,00244x38,4420022x110,2200021414414141200002x16,414120000214414141112000021414414441200002x16,4111200002144441441120000211411114412000021414441141200022x110,22002x412,200x214,",
    },
    Scenery: {
        Doormat: "p[0,1,4]0021x210,12",
    },
    Terrain: {
        FloorLinedHorizontal: "p[3,4]1000",
        WallIndoorLightWithDarkBottom: "p[1,2,3,4]3x07,20202221",
    },
    Text: {
        BlackSquare: "x24,",
        CharA: "p[0,2]x011,1x06,101x05,101000010001000x15,001x05,101x05,10",
        CharB: "p[0,2]x08,x15,0001000010010000100x16,001x05,101x05,10x16,00",
        CharC: "p[0,2]x010,111100010000101x07,1x07,1x08,100001000111100",
        CharD: "p[0,2]x08,x15,000100001001x05,101x05,101x05,1010000100x15,000",
        CharE: "p[0,2]x08,x17,01x07,1x07,x16,001x07,1x07,x17,0",
        CharF: "p[0,2]x08,x17,01x07,1x07,x16,001x07,1x07,1",
        CharG: "p[0,2]x010,111100010000101x07,100111101x05,100100001000111100",
        CharH: "p[0,2]x08,1x05,101x05,101x05,10x17,01x05,101x05,101x05,10",
        CharI: "p[0,2]x09,x15,x05,1x07,1x07,1x07,1x07,1x05,x15,00",
        CharJ: "p[0,2]x09,x16,x05,1x07,1x07,1000100010001000100001110000",
        CharK: "p[0,2]x08,1000010010001000100100001011000011001000100001001x05,10",
        CharL: "p[0,2]x08,1x07,1x07,1x07,1x07,1x07,1x07,x17,0",
        CharM: "p[0,2]x08,1x05,101100011010101010100100101x05,101x05,101x05,10",
        CharN: "p[0,2]x08,1x05,1011000010101000101001001010001010100001101x05,10",
        CharO: "p[0,2]x010,111000010001001x05,101x05,101x05,100100010000111000",
        CharP: "p[0,2]x08,x16,001x05,101x05,10x16,001x07,1x07,1",
        CharQ: "p[0,2]x010,111000010001001x05,101x05,10100010100100010000111010",
        CharR: "p[0,2]x08,x16,001x05,101x05,10x16,0010001000100001001x05,10",
        CharS: "p[0,2]x09,1111000100001001x08,x15,x08,101x05,100x15,00",
        CharT: "p[0,2]x08,x17,00001x07,1x07,1x07,1x07,1x07,10000",
        CharU: "p[0,2]x08,1x05,101x05,101x05,101x05,101x05,100100001000x15,0",
        CharV: "p[0,2]x08,1x05,101x05,10010001000100010000101x05,101x06,10000",
        CharW: "p[0,2]x08,1x05,1010010010101010101010101011000110110001101x05,10",
        CharX: "p[0,2]x08,110001100100010000101x06,1x06,1010000100010011000110",
        CharY: "p[0,2]x08,1x05,100100010000101x06,1x07,1x07,1x07,10000",
        CharZ: "p[0,2]x08,x17,x06,1x06,1x06,1x06,1x06,1x06,x17,0",
        Chara: "p[0,2]x026,111x08,100001111000100010000x15,0",
        Charb: "p[0,2]x09,1x07,1x07,1x07,x15,0001000010010000100x15,00",
        Charc: "p[0,2]x026,1111000100001001x07,100001000111100",
        Chard: "p[0,2]x014,1x07,1x07,1000x15,0010000100100001000x15,0",
        Chare: "p[0,2]x026,111100010000100x16,001x08,x15,0",
        Charf: "p[0,1,2]x08,111122x15,211211112x15,x26,11112x17,2x17,21111",
        Charg: "p[0,2]x018,x15,0010000100100001000x15,x07,100x15,00",
        Charh: "p[0,2]x09,1x07,1x07,1x07,1111000010001000100010001000100",
        Chari: "p[0,2]x019,1x015,1x07,1x07,1x07,10000",
        Charj: "p[0,2]x012,1x015,1x07,1x07,1x07,1x05,110000",
        Chark: "p[0,2]x09,1x07,1x07,100011001011000011x06,101100001000110",
        Charl: "p[0,2]x011,1x07,1x07,1x07,1x07,1x07,1x07,10000",
        Charm: "p[0,2]x024,1110110010010010100100101001001010010010",
        Charn: "p[0,2]x026,11110000100010001000100010001000100010",
        Charo: "p[0,2]x026,11110001000010010000100100001000111100",
        Charp: "p[0,2]x017,x15,0001000010010000100x15,0001x07,1",
        Charq: "p[0,2]x018,x15,0010000100100001000x15,x07,1x07,10",
        Charr: "p[0,2]x025,10011100101x05,11x06,1x07,1",
        Chars: "p[0,2]x026,11110001x08,1111x08,100x15,00",
        Chart: "p[0,2]x019,1x05,x15,x05,1x07,1x07,1x08,1100",
        Charu: "p[0,2]x025,100010001000100010001000100010000111100",
        Charv: "p[0,2]x025,1000100010001000100010000101x06,10000",
        Charw: "p[0,2]x024,1x05,1010010010100100101010101001000100",
        Charx: "p[0,2]x024,1100010000101x06,1x06,10100001000110",
        Chary: "p[0,2]x017,10001000100010001000100001111x07,10001111000",
        Charz: "p[0,2]x025,x16,x06,1x05,11x05,1x06,x16,0",
        Char0: "p[0,2]x018,1110000100110011000110110001100110010000111000",
        Char1: "p[0,2]x018,11x05,111x06,11x06,11x06,110000x16,00",
        Char2: "p[0,2]x017,x15,001100011x05,111001111000111x05,x17,0",
        Char3: "p[0,2]x017,x16,x05,110000111x08,110110001100x15,00",
        Char4: "p[0,2]x019,11100001111000110110011001100x17,x05,1100",
        Char5: "p[0,2]x016,x16,0011x06,x16,x07,110110001100x15,00",
        Char6: "p[0,2]x017,x15,0011x06,x16,0011000110110001100x15,00",
        Char7: "p[0,2]x016,x17,01100011x05,11x05,11x05,11x06,110000",
        Char8: "p[0,2]x017,x15,00110001100x15,0011000110110001100x15,00",
        Char9: "p[0,2]x017,x15,0011000110110001100x16,x06,1100x15,00",
        CharSpace: "0",
        CharTimes: "p[0,2]x017,100010000101x06,1x06,101000010001",
        CharLeftParenthesis: "p[0,2]x05,11x05,1x06,1x07,1x07,1x08,1x08,11",
        CharRightParenthesis: "p[0,2]11x08,1x08,1x07,1x07,1x06,1x05,11",
        CharColon: "p[0,2]x019,11x06,11x022,11x06,11000",
        CharSemicolon: "p[0,2]x011,11x06,11x014,11x06,11x07,1x06,10000",
        CharLeftSquareBracket: "p[0,2]x013,111x05,1x07,1x07,1x07,1x07,1x07,111",
        CharRightSquareBracket: "p[0,2]x08,111x07,1x07,1x07,1x07,1x07,1x05,111",
        CharNo: "p[0,2]x08,1001000011010x17,01011011010110010111",
        CharID: "p[0,2]x09,101110001010010010100100101001001011100",
        CharHyphen: "p[0,2]x033,x16,00",
        CharUnderscore: "p[0,2]x049,x17,0x17,",
        CharQuestionMark: "p[0,2]x09,x16,011100x15,00111000011x014,11x06,11000",
        CharExclamationMark: "p[0,2]00011x05,11110000111100001111x05,11x014,11x06,11000",
        CharSlash: "p[0,2]x014,1x06,1x06,1x06,1x06,1x06,1x06,1",
        CharPeriod: "p[0,2]x042,11x06,11",
        CharComma: "p[0,2]x033,11x06,11x07,1x06,1",
        CharED: "p[0,2]1111000011x06,1111000011001110x16,01000011010000110100001110",
        CharApostrophe: "p[0,2]00110110",
        CharFeet: "p[0,2]001101101000",
        CharInches: "p[0,1,2]0022000022112221112200012000",
        ChareFancy: "p[0,2]x010,11000011x08,111100010000100x16,001x08,x15,0",
        CharCircle: "p[1,3]01110x115,01110",
        CharHP: "p[0,2]110101111001101011010x15,011110011010110001",
        CharPP: "p[0,2]x16,00x16,01100011011000111100011011000111100011011000x18,00x16,011x06,11x05,11x06,11",
        CharTo: "p[0,2]01x05,11101100101001010100101010010010110",
        Char$: "p[0,2]0111101001011110100011100010001110001000",
        CharLevel: "p[0,2]001100101100001100101100001111",
        CharArrowRight: "p[0,2]110001110011110x19,01110011000",
        Line: "p[2]0000",
        Menu: {
            normal: [
                "multiple",
                "corners",
                {
                    top: "p[1,2]x016,x18,x08,x116,x016,",
                    topRight: "p[1,2]x011,11000101011000x16,0010000101010010001011x05,101000",
                    right: "p[1,2]00101x05,101x05,101x05,101x05,101x05,101x05,101x05,101000",
                    bottomRight: "p[1,2]0010100001011000101011000x16,001000010101001x05,11x011,",
                    bottom: "p[1,2]x016,x18,x08,x116,x016,",
                    bottomLeft: "p[1,2]000101x05,11010001011010x16,0010000100010010100011x011,",
                    left: "p[1,2]00101x05,101x05,101x05,101x05,101x05,101x05,101x05,101000",
                    topLeft: "p[1,2]x011,11x05,1011010x16,001000010001001010001101000010100",
                    middle: "p[1]x064,",
                },
            ],
        },
    },
};
